
export default function Livros() {

  return (
    <div className="bg-[#f5f5f5] overflow-hidden">
      <div className="grid lg:grid-cols-2 pt-[124px] mx-auto 2xl:w-[1568px] w-[90%]">
      <div className="flex flex-col justify-center lg:w-full mx-auto mt-[32px]">
          <h2 className="text-[56px] font-black text-[#502274] leading-[58px] mb-6">
            LIVROS
          </h2>
          <p className="text-[20px] text-[#502274] mb-8">
            O aprimoramento deve ser uma constante em nossas vidas. Umas das formas de autodesenvolvimento é a leitura.
            Aprecio os livros temáticos ligados às Terapias Integrativas e sobre experiências de vida. Destaco, aqui, alguns que considero importantes,
            como o da íris Abravanel.
          </p>

        </div>
        <div className="items-center justify-center relative flex w-[100%]">
          <div className="relative w-fit">
            <img src="livros1.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 " />
            <img src="livros2.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-5000" style={{ animationDelay: "5s" }} />
            <img src="livros3.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-10000 " style={{ animationDelay: "10s" }} />
            <img src="livros4.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-15000 " style={{ animationDelay: "15s" }} />
            <img src="/16.jpg" className="2xl:h-[518px] 2xl:w-[336px] md:h-[500px] w-[220px] h-[460px] md:w-[280px] bg-[#E9C0E9]  skew-x-img rounded-2xl z-10" />
          </div>
        </div>
      </div>
      <div className="pb-[64px] mt-16 mx-auto 2xl:w-[1568px] w-[90%]">
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            A Segunda Vida - Um guia para a mulher madura, de Marisa Sanabria.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Segundo a psicóloga Marisa Sanabria, a segunda vida da mulher começa quando ela toma posse de si mesma e de quem ela é,
            por meio de transformações inevitáveis, que o tempo traz e que devem ser enfrentadas para o seu amadurecimento.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Feminino Ativo, Feminino Solar, de Valérie Dupont
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Neste livro, Valérie Dupont nos ajuda a descobrir as mulheres, que, em todos os tempos e lugares, desempenharam papéis
            positivos, não obstante seus nomes estarem, hoje, ignorados ou esquecidos.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            A Libertação do Masculino - Medicina para Masculinidade Saudável, de Niels Koldewijn.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Um baralho de conexão simples, porém profunda, que se concentra na abertura de um diálogo e de um espaço interior sobre o
            universo masculino. São 79 cartas, divididas em 6 categorias. Feito para os homens, mas também para mulheres interessadas
            em equilibrar sua energia masculina
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Desvendando o Poder Oculto da Sexualidade Feminina, de Maitreyi D. Piontek.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Este livro busca estimular mulheres de todas as idades a descobrir os tesouros da sexualidade feminina, desenvolvendo o
            potencial de cura por meio do amor. Serve ainda como uma contribuição para uma sociedade mais alegre e natural, que torne o
            mundo melhor.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Mulheres que correm com os Lobos - Mitos e Histórias do Arquétipo da Mulher Selvagem. Clarissa Pinkola Estés.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            A autora é psicóloga junguiana. Ela pesquisou e estudou contos, fábulas, histórias folclóricas e mitos ancestrais de diversas
            partes do mundo e os utilizou como base para o desenvolvimento de seu trabalho. O livro traz 14 histórias, divididas em 14
            capítulos, cuja sabedoria é analisada e interpretada por Clarissa numa escrita metafórica, que movimenta o subconsciente,
            convidando as leitoras para uma viagem pelos caminhos subterrâneos da psiquê. Ela ressalta que, quando a mulher compartilha
            suas dores, começa a se libertar e se curar.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Mulher - Em Busca da Feminilidade Perdida, organização de Connie Zweig
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Após as conquistas do Feminismo e um aprofundamento no desenvolvimento pessoal, as mulheres têm agora a oportunidade de
            viver uma feminilidade consciente, com os benefícios de uma independência tão duramente conquistada. É o que o livro se
            propõe a ensinar.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Bom Dia! Leituras Diárias com Stormie Omartian
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Nesta obra, a autora nos convida a começar o dia em sintonia com Deus. Todos os dias, uma nova mensagem pode fazer
            diferença em nossas vidas. Uma maneira simples e prazerosa de assegurar um bom dia todo dia
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Recados Disfarçados, de Íris Abravanel.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            De maneira simples e bem-humorada, Íris Abravanel faz uso de algumas crônicas como recados que refletem cenas do dia-a-dia
            de milhares de mulheres, que são mães, esposas, trabalhadoras e que estão sempre dispostas a manter o bem estar da família.
            Livro agradável, divertido e emocionante.
          </p>
        </div>
        <div className="w-[100%] mt-3">
          <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
            Guardiãs do Círculo das Mulheres, de Célia de Freitas, Fátima Tolentino, Marisa Sanabria.
          </h3>
          <p className="text-[16px] text-[#502274] mb-8">
            Este livro é um forte testemunho de um processo que pode ocorrer nas profundezas da alma feminina. Traz depoimentos que
            mostram as dificuldades e as alegrias de uma jornada em busca do feminino. Realizando círculos de mulheres e oficinas, as
            autoras propõem uma formação para mulheres com o propósito de se autoconhecerem.
          </p>
        </div>

      </div>

    </div>
  )
}