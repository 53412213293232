

export default function QuebraSessao(props) {

    return (
        <div className={` py-[64px]`} style={{background: props.bg ? props.bg : "#ffffff"}}>
            <div className="max-w-[1568px] w-[90%] mx-auto">
                <h2 className="text-[42px] font-bold text-[#502274] leading-[42px] mb-4">{props.titulo}</h2>
                <p className="text-[16px] text-[#502274]">
                    {props.texto}
                </p>
            </div>
        </div>
    )
}