
export default function SessaoSimplesEsquerda(props){

  return (
    <div style={{background: props.bg ? props.bg : "#A8816D"}}>
      <div className="grid lg:grid-cols-2 py-[124px] 2xl:w-[1568px] w-[90%] mx-auto ">
        <div className="flex flex-col justify-center">
          <h2 className="text-[56px] font-black text-[#fff] leading-[58px] mb-6">
            {props.titulo}
          </h2>
          <p className="text-[20px] text-[#fff] mb-8">
          {props.texto}
          </p>
          <a href="#contato"  >
            <div className="w-[192px] h-[64px] flex justify-center items-center rounded-full bg-[#A83F97] text-[1.25rem] text-[#fff]" >
              Contato
            </div>
          </a>
        </div>
        <div className="sm:flex hidden items-center justify-center md:m-auto relative flex-wrap gap-4 w-[520px] mt-[42px] " >
            <img src={props.img} className="2xl:h-[518px] 2xl:w-[336px] md:h-[500px] w-[220px] h-[460px] md:w-[280px] bg-[#E9C0E9] rounded-2xl z-10" />
        </div>
      </div>

    </div>
  )
}