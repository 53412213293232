
export default function Footer() {
  return (
    <div className="bg-[#483058] py-8">
      <div className="flex flex-col md:flex-row  justify-between md:items-end 2xl:max-w-[1568px] w-[90%] mx-auto"> 
        <img src="Logotipo-02.png" alt="" className="w-[480px]"/>
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[8px] items-end">
            <p className="text-[#F2F2F2] text-[14px] leading-[15px] ">Siga-nos nas redes sociais</p>
            <div className="flex gap-[8px]">
          <a
            href="https://www.facebook.com/tjrnoficial"
            target="_blank"
            rel="noreferrer"
            className="w-[32px] h-[32px]  rounded-[50%] hover:bg-[#4267B2]"
          >
            <span >
              <img
                src="facebook.svg"
                alt="Visitar TJRN no Facebook"
                width={32}
                height={32}
              />
            </span>
          </a>
          <a
            href="https://www.facebook.com/tjrnoficial"
            target="_blank"
            rel="noreferrer"
            className="w-[32px] h-[32px] rounded-[50%] hover:bg-[#C13584]"
          >
            <span >
              <img
                src="instagram.svg"
                alt="Visitar TJRN no Instagram"
                width={32}
                height={32}
              />
            </span>
          </a>
          <a
            href="https://www.facebook.com/tjrnoficial"
            target="_blank"
            rel="noreferrer"
            className="w-[32px] h-[32px] rounded-[50%] hover:bg-[#1DA1F2]"
          >
            <span >
              <img
                src="twitter.svg"
                alt="Visitar TJRN no Twitter"
                width={32}
                height={32}
              />
            </span>
          </a>
          <a
            href="https://www.facebook.com/tjrnoficial"
            target="_blank"
            rel="noreferrer"
            className="w-[32px] h-[32px] hover:bg-[#ff0000] rounded-[50%]"
          >
            <span className="w-full h-full">
              <img
                src="youtube.svg"
                alt="Visitar TJRN no YouTube"
                width={32}
                height={32}
              />
            </span>
          </a>
        </div>

      </div>
        </div>
      </div>
    </div>
  );
}
