
export default function Quebra2() {

    return (
        <div className="bg-[#f5f5f5]">
            <div className="flex flex-1 flex-col py-[124px] mx-auto 2xl:w-[1568px] w-[90%]">
                <div>
                    <h2 className="text-[56px] font-black text-[#502274] leading-[58px] mb-6">As 14 leis da apometria</h2>
                    <p className="text-[20px] text-[#502274] mb-8">Essas leis foram extraídas do Livro Espírito e Matéria, do doutor José Lacerda de Azevedo, sendo que hoje elas não são aplicáveis da
                        mesma forma como eram antes. Existem diversas pessoas, que canalizaram métodos variados, tendo estas leis como base.
                        As 14 leis da Apometria são as listadas a seguir:</p>
                </div>
                <div className="flex gap-6">
                    <p className="text-[20px] text-[#502274] mb-8">1º Lei: Lei do Desdobramento Espiritual (Lei Básica da Apometria); <br />
                        2º Lei: Lei do Acoplamento Físico;<br />
                        3º Lei: Lei da Ação à Distância pelo Espírito Desdobrado (Lei das Viagens Astrais);<br />
                        4º Lei: Lei da Formação dos Campos de Força;<br />
                        5º Lei: Lei da Revitalização dos Médiuns;<br />
                        6º Lei: Lei da Condução do Espírito Desdobrado, de pacientes encarnados para planos mais altos em hospital do astral;<br />
                        7º Lei: Lei da Ação dos Espíritos Desencarnados e Socorristas sobre pacientes desdobrados;
                        8º Lei: Lei do Ajustamento de Sintonia Vibratória dos Espíritos Desencarnados, com médium ou com outros espíritos desencarnados ou
                        Lei do Ajustamento de Sintonia com o Ambiente, para onde, momentaneamente, os espíritos desencarnados forem enviados;<br />
                        9º Lei: Lei do Deslocamento do Espírito no Espaço e no Tempo;<br />
                        10º Lei: Lei da Dissociação do Espaço e do Tempo;<br />
                        11º Lei: Lei da Ação Telúrica sobre os Espíritos Desencarnados que evitam a reencarnação;<br />
                        12º Lei: Lei do Choque do Tempo;<br />
                        13º Lei: Lei da Influência dos Espíritos Desencarnados em Sofrimento que vivem ainda no passado sobre o presente dos obsedados;<br />
                        14º Lei: Lei dos Fenômenos de Aplicação Energética de Vetores. <br />
                    </p>
                </div>
            </div>
        </div>
    )
}