

export default function Cursos(){

  const casos = [
    {
      nome: "Caio Brito",
      image: "/p1.jpeg",
      subTitulo: 'Inacreditável como a Apometria equilibrou e renovou meu ânimo de viver, abrindo portas ao desconhecido...',
      descricao: 'Primeiro tenho que agradecer a sua intercessão em minha vida! Sabe, eu nunca tinha ouvido falar em Apometria e tenho que confessar que, no início, olhei com olhos julgadores, achando que não seria real! Hoje, eu preciso avisar a todos sobre os “milagres” que foram a mim proporcionados.Inacreditável como a Apometria equilibrou e renovou meu ânimo de viver, abrindo portas ao desconhecido... Trazendo paz para uma alma angustiada! Sem mencionar que, durante as sessões, tive sensações emocionais, enxerguei luzes de diversas cores, senti raios percorrendo meu corpo.Por mim, eu faria Apometria toda semana, todos os dias! Eu recomendo muito que todos confiem nessa pessoa iluminada que é a Andréa! Muito obrigado.Deus te abençoe',
      arroba: "@caiobrito.art"
    },
    {
      nome: "Marianne Oliveira",
      image: "/p2.jpeg",
      subTitulo: 'Confira o relato da experiência terapêutica da Marianne!',
      descricao: 'O mundo em que vivemos hoje nos leva a ser o que julgam ser o certo! Mas, é aí que muitos se afogam... Tanta hipocrisia! Eu já estava num beco quase sem saída, quando me foi apresentada a terapia com a Andréa De Paula. Costumo dizer que minha vida é dividida em “antes de Andréa” e “depois de Andréa”, kkkk! É algo inexplicável: tudo começa a fazer sentido e quando você vê, tudo está fluindo! A gente cria uma conexão com a gente mesma e começa a entender nossos próprios sinais... E tudo fica mais leve! Uma dica: façam terapia!',
      arroba: "@maryyoliveiraoficial"
    },
    {
      nome: "Mirele Maravilhas",
      image: "/p3.jpeg",
      subTitulo: 'Minha gratidão pela atenção e o atendimento em Apometria!',
      descricao: '“Passo para registrar minha gratidão pela atenção e o atendimento em Apometria. Ainda estou processando, mas já é possível perceber os benefícios na primeira sessão.',
      arroba: "@mi.maravilhas"
    },
  ];

  return (
    <div id="cursos" className="bg-[#69346D] relative pt-[64px] pb-[62px] lg:pb-[120px]">
      <div className="2xl:w-[1568px] w-[90%] mx-auto">
        <h2 className="font-black font-inter text-[#ffffff] text-[56px] lg:text-[72px] leading-[57px] lg:leading-[87px] mb-[42px]  ">
          Casos
        </h2>
      </div>
      <div className="2xl:w-[1568px] w-[90%] mx-auto grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-[24px]">
        {casos.map((caso)=>{
          return(
          <div className=" bg-zinc-50 rounded-[24px] overflow-hidden border-2 border-[#E9C0E9]" >
            <div className="flex flex-1 items-center justify-center">
              <img src={caso.image} alt="" />
            </div>
            <div className="p-[16px]">
              <h3 className="text-[32px] text-[#402D54] font-bold">{caso.nome}</h3>
              <p className="text-[18px] text-[#69346D] font-semibold">"{caso.subTitulo}"</p>
              <p className="text-[16px] text-[#69346D] font-normal">{caso.descricao}</p>
              <p className="text-[16px] text-[#69346D] font-normal mt-[8px]">Instagram: {caso.arroba}</p>
            </div>
          </div> 
          )
        })}
      </div>
    </div>
  )
}