
export default function Filmes() {

    return (
        <div className="bg-[#ffffff] overflow-hidden">
            <div className="grid lg:grid-cols-2 pt-[124px] mx-auto 2xl:w-[1568px] w-[90%]">
                <div className="flex flex-col justify-center lg:w-full mx-auto mt-[32px]">
                    <h2 className="text-[56px] font-black text-[#502274] leading-[58px] mb-6">
                        FILMES & MÚSICA
                    </h2>
                    <p className="text-[20px] text-[#502274] mb-8">
                        A sétima arte nos traz saberes e inspirações de como viver em harmonia com o mundo cósmico, consigo mesmo e com nossos semelhantes.
                        Listo, aqui, algumas produções, que podem nos inspirar a refletir, meditar e sermos seres humanos prósperos, fraternos e amorosos.
                    </p>

                </div>
                <div className="items-center justify-center relative flex w-[100%]">
                    <div className="relative w-fit">
                        <img src="livros1.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 " />
                        <img src="livros2.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-5000" style={{ animationDelay: "5s" }} />
                        <img src="livros3.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-10000 " style={{ animationDelay: "10s" }} />
                        <img src="livros4.png" alt="livro" className="absolute top-[200px] left-[-120px] w-[95px] h-[95px] z-50 animate-elipse ease-linear rotate-12 delay-15000 " style={{ animationDelay: "15s" }} />
                        <img src="/32.jpg" className="2xl:h-[518px] 2xl:w-[336px] md:h-[500px] w-[220px] h-[460px] md:w-[280px] bg-[#E9C0E9]  -skew-x-img rounded-2xl z-10" />
                    </div>
                </div>
            </div>
            <div className="pb-[64px] mt-16 mx-auto 2xl:w-[1568px] w-[90%]">
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        Os Homens são de Marte e É p’ra Lá que eu Vou (2014, comédia/romance, 1h48min)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        Mônica Martelli dá vida à personagem Fernanda, organizadora de casamentos que, por ironia do destino, é solteira. Em busca do
                        amor, Fernanda se envolve em várias situações, que vão do riso ao choro; drama e comédia que nos cativa o tempo todo.
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        Sob o Sol da Toscana (2003, romance/comédia, 1h55min)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        Francês Mayes descobre que seu marido é infiel e se divorcia. Sua amiga Patty sugere que ela faça uma viagem pela Itália. Ela
                        gosta da ideia e viaja para lá. Durante o passeio, ela compra, impulsivamente, uma casa num vilarejo toscano e inicia uma nova vida
                        ao lado de pessoas interessantes, incluindo o maravilhoso Marcelo
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        Adoráveis Mulheres (2019, romance/drama; 2h15min)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        Nos anos seguintes à Guerra de Secessão, duas irmãs voltam para casa, quando Beth, a tímida irmã caçula, desenvolve uma doença
                        devastadora, que muda para sempre a vida de todas elas. Com personalidades completamente diferentes, elas enfrentam desafios
                        para se manterem unidas pelo amor que as nutrem umas pelas outras.
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        O menino que Descobriu o Vento
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        Baseado em fatos reais, o filme retrata a vida de uma criança africana, que, mesmo diante das dificuldades, como a falta de dinheiro,
                        problemas familiares e a seca, mantém a persistência nos estudos. Ele ama estudar e demonstra o poder que o estudo e a leitura
                        exercem sobre a sua vida. Com inovação e a criatividade inventa um modo de produzir energia eólica, mesmo diante de
                        circunstâncias desfavoráveis
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        Donzela (2024, fantasia, 1h49min)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        O filme trabalha conceitos de sororidade e empoderamento feminino, numa época em que as mulheres não tinham voz. A princesa
                        Elodie é abnegada e concorda em um casamento imposto, para salvar seu próprio reino. Porém, ao descobrir que a realeza pretende
                        sacrificá-la, ela ganha força e coragem para enfrentar um dragão que aniquila mulheres inocentes de seu reino.
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                        A Cinco Passos de Você (2019, romance/drama, 1h57min)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                        A adolescente Stella Grant passa muito tempo no hospital, devido a uma fibrose cística. Lá ela conhece Will Newman, que sofre da
                        mesma doença que ela. Eles se apaixonam, mas são obrigados a manter distância um do outro por questões preventivas de saúde.
                        Eles reagem diante da imposição, mostrando perspectivas diferentes de se enxergar a vida, quando a felicidade é encontrada em
                        situações desafiadoras.
                    </p>
                </div>
                <div className="w-[100%] mt-3">
                    <h3 className="text-[28px] font-semibold text-[#502274] leading-[28px]">
                    Bolero (de Ravel)
                    </h3>
                    <p className="text-[16px] text-[#502274] mb-8">
                    Composta, inicialmente, para uma peça de balé em 1927, o Bolero é a obra mais famosa de Maurice Ravel, embora o compositor a considerasse
como um simples exercício de orquestração. Trata-se de uma melodia uniforme e repetitiva, em que a única sensação de mudança se dá pela
dinâmica da orquestração, com um crescendo progressivo, com o qual o compositor pretendia uma recriação dos movimentos do balé clássico.
A peça é leve e ao mesmo tempo, empolgante, sugerindo uma conexão transcendental.
                    </p>
                </div>


            </div>

        </div>
    )
}