
export default function SessaoSimpleDireita(props) {
 console.log(props.bg)
    return (
        <div style={{background: props.bg != undefined ? props.bg : "#ffffff"}}>
            <div className={`grid lg:grid-cols-2 py-[124px] mx-auto 2xl:w-[1568px] w-[90%]`}>
                <div className="items-center justify-center relative flex w-[100%] overflow-hidden">
                    <img src={props.img} className="2xl:h-[518px] 2xl:w-[336px] md:h-[500px] w-[220px] h-[460px] md:w-[280px] bg-[#E9C0E9] rounded-2xl z-10" />
                </div>
                <div className="flex flex-col justify-center lg:w-full w-[90%] mx-auto mt-[32px]">
                    <h2 className="text-[56px] font-black text-[#502274] leading-[58px] mb-6">
                        {props.titulo}
                    </h2>
                    <p className="text-[20px] text-[#502274] mb-8">
                        {props.texto}
                    </p>
                    <a href="#contato"  >
                        <div className="w-[192px] h-[64px] flex justify-center items-center rounded-full bg-[#502274] text-[1.25rem] text-[#fff]" >
                            Contato
                        </div>
                    </a>
                </div>

            </div>
        </div>
    )
}