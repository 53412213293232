import { useState } from "react";

export default function Header() {
  const [navGlass, setNavGlass] = useState(false);
  const [dropdownOpen, setdropdownOpen] = useState(false);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setNavGlass(true);
      } else {
        setNavGlass(false);
      }
    });
  }

  return (
    <div className="px-[2rem]">
      <div
      className={`mx-auto h-[80px]  ${
        navGlass
          ? "fixed top-0 left-0 right-0 mt-[0px] w-full"
          : "relative mb-[-132px] mt-[52px] max-w-[1568px]"
      }  z-[1000] transition-colors`}
    >
      <div
        className={`absolute h-full  ${
          navGlass ? "w-[100%]" : "w-[90%] rounded-full"
        } right-0 bg-white border-[#FFFFFF10] border-[1px] backdrop-blur-[20px] drop-shadow-lg z-[1000]`}
      />
      <div
        className={`h-[80px] rounded-full  ${
          navGlass === true
            ? "bg-transparent w-full pr-[2rem]"
            : "bg-white max-w-[1568px] pr-[2rem]"
        } relative z-[1100]`}
      >
        <div className="max-w-[1568px] h-full mx-auto flex justify-between items-center">
          <div
            className={`text-white rounded-full  h-full flex items-center justify-center z-[1100]`}
          >
            {navGlass ? (
              <img src="Logotipo-01.png" alt="ipcjus logo" className="w-[292px]" />
            ) : ( 
              <img src="Logotipo-01.png" alt="ipcjus logo" className="w-[292px]" />
            )}
          </div>
          
          <nav className="hidden md:flex gap-[48px] mr-[24px] text-[20px] font-normal z-[1100]">
            <a
              href="#sobre"
            >
              <span className="">
              Sobre
              </span>
            </a>
            <a
              href="#audio_aulas"
            >
              <span className=" ">
              Áudio Aulas
              </span>
            </a>
            <a
              href="#depoimentos"
            >
              <span className="">
              Depoimentos
              </span>
            </a>
            <a
              href="#cursos"
            >
              <span className="">
              Cursos
              </span>
            </a>
            <a
              href="#contato"
            >
              <span className="">
              Contato
              </span>
            </a>
          </nav>

          <div
            onClick={() => setdropdownOpen(!dropdownOpen)}
            className=" md:hidden flex justify-center items-center
                 hover:cursor-pointer
                 "
          >
            Menu
          </div>

          <div
            className={`${
              dropdownOpen
                ? `top-full opacity-100 visible`
                : "top-[110%] invisible opacity-0"
            } absolute left-0 z-40 mt-2 w-full rounded border-[.5px] border-light bg-white py-5 shadow-card transition-all`}
          >
            <a
              href="#ranking"
              className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
              onClick={()=>setdropdownOpen(!dropdownOpen)}
            >
              Ranking
            </a>
            <a
              href="#videos"
              className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
              onClick={()=>setdropdownOpen(!dropdownOpen)}
            >
              Vídeos
            </a>
            <a
              href="#perguntas"
              className="block py-2 px-5 text-base font-semibold text-body-color hover:bg-primary hover:bg-opacity-5 hover:text-primary"
              onClick={()=>setdropdownOpen(!dropdownOpen)}
            >
              Perguntas
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
