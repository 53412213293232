
export default function Eventos() {

    return (
        <div className="bg-[#f5f5f5]">
            <div className="flex flex-1 flex-col py-[112px] mx-auto 2xl:w-[1568px] w-[90%]">
                <div>
                    <h2 className="text-[52px] font-black text-[#502274] leading-[58px] mb-6">Eventos: Vivências I Workshops l Palestras l Imersões</h2>
                </div>
                <div className="">
                    <div>
                        <h3 className="text-[28px] font-semibold text-[#502274] leading-[16px] mb-3">
                            O PODER CURATIVO DAS MANDALAS
                        </h3>
                        <p className="text-[16px] text-[#502274] mb-8">
                            Essa prática concentra-se na confecção de mandalas (círculo mágico) por meio de instrumentos da Arteterapia. É um trabalho que estimula
                            e aguça a criatividade do ser, ajuda a tratar a ansiedade e a organizar a energia do corpo. O trabalho é uma espécie de jornada de almas,
                            que se conectam com as partes mais profundas do inconsciente, gerando imagens usadas para cura e meditação.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-[28px] font-semibold text-[#502274] leading-[16px] mb-3">
                            RODA DE MULHERES SÁBIAS E FORTES
                        </h3>
                        <p className="text-[16px] text-[#502274] mb-8">
                            A Roda de Mulheres Sábias e Fortes é um trabalho terapêutico, que propicia momentos mágicos para que nós, mulheres, possamos ser
                            donas de nossa própria história. Partilhamos o que há de melhor em nós mesmas: emoções, conhecimentos, sabedoria e muita
                            transmutação de energia estagnada.
                            “Toda conversa de vibração de energia elevada, torna-se sagrada.” Andréa De Paula
                        </p>
                    </div>
                    <div>
                        <h3 className="text-[28px] font-semibold text-[#502274] leading-[16px] mb-3">
                            CONTOTERAPIA COLETIVA
                        </h3>
                        <p className="text-[16px] text-[#502274] mb-8">
                            É uma vivência em grupo, que utiliza a técnica terapêutica da Contoterapia. Um conto é lido e cada pessoa fala sobre a parte que mais a
                            tocou. A partir daí, são feitas pontuações sobre o que representam essas partes da história. Sob uma visão psicanalítica, uma roda de
                            partilha é aberta e cada participante fale sobre sua própria história, relacionada ao tema em discussão
                        </p>
                    </div>
                    <div>
                        <h3 className="text-[28px] font-semibold text-[#502274] leading-[16px] mb-3">
                            CURANDO A CRIANÇA INTERIOR FERIDA
                        </h3>
                        <p className="text-[16px] text-[#502274] mb-8">
                            Baseado na Arteterapia, o grupo monta um mosaico de fotos, que registra a vida de cada participante do zero aos sete anos de idade. A
                            partir dos ensinamentos antroposóficos de Rudolf Steiner, é feita uma pontuação sobre o desenvolvimento do primeiro setênio (ou ciclo)
                            de vida de uma pessoa. A partir daí, uma roda de partilha é aberta, e cada participante fala sobre suas vivências nesse período,
                            identificando-as, pontuando-as e ressignificando-as por meio do que foi vivido na roda. Dessa forma, busca-se limpar as energias mal
                            qualificadas do primeiro setênio de vida e propiciar uma vivência de cura da criança interna ferida dos participantes.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-[28px] font-semibold text-[#502274] leading-[16px] mb-3">
                            EQUILIBRANDO O AMOR SAUDÁVEL (YIN/YANG)
                        </h3>
                        <p className="text-[16px] text-[#502274]">
                            Atividade voltada para o equilíbrio das energias feminina e a masculina.
                            Segundo ensinamentos chineses, a energia feminina (Yin) é a energia criativa, fluida, estimulante, refinada, intuitiva e gentil. A energia
                            masculina (Yang) é a energia da ação e da realização. Ambas as energias coexistem em nós e as utilizamos quando precisamos. São
                            forças opostas e complementares que, segundo o princípio da dualidade, não vivem uma sem a outra, pois, só assim se pode gerar atração
                            e desejo. Para que as relações se equilibrem, é necessário antes equilibrar essas duas energias dentro de nós, o que é feito em uma roda
                            de partilha, com a vivência da limpeza do ventre.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}