import { useEffect, useState } from "react";

export default function Sobre() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0, z: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      let mouseXPercentage = 0;
      let mouseYPercentage = 0;
      let mouseZPercentage = 0;

      if (mouseX < screenWidth / 2 && mouseY < screenHeight / 2) {
        mouseXPercentage = (50 - (mouseX / (screenWidth)) * 100)
      } else if (mouseX < screenWidth / 2 && mouseY > screenHeight / 2){
        mouseXPercentage = -(50 - (mouseX / (screenWidth)) * 100)
      } else if (mouseX > screenWidth / 2 && mouseY < screenHeight / 2){
        mouseXPercentage = +(-50 + (mouseX / (screenWidth)) * 100)
      } else if (mouseX > screenWidth / 2 && mouseY > screenHeight / 2){
        mouseXPercentage = +(-50 + (mouseX / (screenWidth)) * 100)
      }

      if (mouseY < screenHeight / 2 && mouseX < screenWidth / 2) {
        mouseYPercentage = -(-50 + (mouseY / screenHeight) * 100)
      } else if(mouseY < screenHeight / 2 && mouseX > screenWidth / 2){
        mouseYPercentage = -(50 - (mouseY / screenHeight) * 100)
      }else if(mouseY > screenHeight / 2 && mouseX < screenWidth / 2){
        mouseYPercentage = + 50 - (mouseY / screenHeight) * 100
      }else if(mouseY > screenHeight / 2 && mouseX > screenWidth / 2){
        mouseYPercentage = 50 - (mouseY / screenHeight) * 100
      }

      if((mouseX < screenWidth / 2) && (mouseY < screenHeight / 2)){
        mouseZPercentage = mouseYPercentage + mouseXPercentage
      } else if((mouseX > screenWidth / 2) && (mouseY < screenHeight / 2)){
        mouseZPercentage =  mouseYPercentage - mouseXPercentage
      }else if((mouseX < screenWidth / 2) && (mouseY > screenHeight / 2)){
        mouseZPercentage =  mouseYPercentage + mouseXPercentage
      }else if((mouseX > screenWidth / 2) && (mouseY > screenHeight / 2)){
        mouseZPercentage = -mouseYPercentage + mouseXPercentage
      }
      
      
      setMousePosition({ x: mouseXPercentage, y: mouseYPercentage, z: mouseZPercentage });
    };


    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div id="sobre" className="bg-gradient-to-b from-[#A83E97] to-[#4C4990] pt-[154px] pb-[42px]">
      <div className="2xl:w-[1568px] w-[90%] mx-auto py-16 grid lg:grid-cols-2" >
        <div className="text-white flex flex-col justify-center" >
          <h1 className="text-[82px] font-black leading-[82px] mb-[42px]">
            Andréa D’Paula
            <br />
            <p className="text-[34px] font-black leading-[42px] mt-[16px] tracking-[3px]">TERAPEUTA DE ANCORAGEM</p>
          </h1>
          <p className="text-[22px] font-medium mb-8">
            Sou uma mulher que tem o propósito de se conhecer e apoiar pessoas que se propõem a percorrer o caminho instigante do
            autoconhecimento, tornando-se cada dia mais feliz, forte e próspera.
          </p>
          <a href="#contato"  >
            <div className="w-[192px] h-[64px] flex justify-center items-center rounded-full bg-[#E9C0E9] text-[1.25rem] text-[#000]" >
              Contato
            </div>
          </a>
        </div>
        <div className="items-center justify-center w-full hidden lg:block">
          <div className="relative mx-auto w-fit z-10 transition-all ease-in-out duration-100"
            style={{ transform: `rotateX(${mousePosition.y * 0.5}deg) rotateY(${mousePosition.x * 0.5}deg) 
            rotateZ(${(mousePosition.z) *0.05}deg)` }}
          >
            <img src="flor.svg" alt="flor" className={`absolute z-20 transition-all ease-linear xl-[208px] w-[180px] top-[350px] left-[-80px]`}
              style={{ top: mousePosition.y * 0.2 + 350 + "px", left: mousePosition.x * 0.2 - 80 + "px", transform: `rotateX(${mousePosition.y * 0.4}deg) rotateY(${mousePosition.x * 0.4}deg)`, filter: "drop-shadow(6px 10px 4px rgb(0 0 0 / 0.2))" }}
            />
            <div 
              className={` 2xl:h-[618px] 2xl:w-[336px] lg:w-[250px] lg:h-[580px]  xl:w-[280px] xl:h-[580px] h-[580px] overflow-hidden md:w-[336px] sm:w-[336px] w-[250px]  rounded-2xl z-50 shadow-2xl shadow-zinc-900/20`}
              style={{background: `no-repeat 100% url('/woman.jpg)`}}
            >
              <img src="/8.jpg" alt="" className="h-full" />
            </div>

            <img src="flor.svg" alt="flor" className={`absolute z-0 transition-all ease-linear xl-[208px] w-[180px] top-[110px] right-[-110px] `}
              style={{ zIndex: -1, top: mousePosition.y * 0.2 + 110 + "px", right: mousePosition.x * 0.2 - 110 + "px", transform: `rotateX(${mousePosition.y * 0.4}deg) rotateY(${mousePosition.x * 0.4}deg)`, filter: "drop-shadow(6px 10px 4px rgb(0 0 0 / 0.2))" }}
            />

          </div>
        </div>

      </div>
    </div>
  )
}