
export default function Radiestesia() {

    return (
        <div className="bg-[#f5f5f5]">
            <div className="flex flex-1 flex-col py-[112px] mx-auto 2xl:w-[1568px] w-[90%]">
                <div>
                    <h2 className="text-[56px] font-black text-[#502274] leading-[58px] mb-6">RADIESTESIA</h2>
                    <p className="text-[20px] text-[#502274] mb-8">É uma técnica que tem por objetivo medir e detectar campos energéticos, por meio de vários instrumentos, como, por exemplo, o pêndulo.
                        Essa técnica terapêutica pode ajudar as pessoas nos seguintes aspectos:</p>
                </div>
 
                <div className="flex gap-6 items-center">
                    <p className="text-[20px] text-[#502274] ">
                        • Distúrbios emocionais e psíquicos; <br />
                        • Estresse, tensão, nervosismo e agitação;<br />
                        • Perdas e estagnação profissional;<br />
                        • Perdas e bloqueios financeiros;<br />
                        • Distúrbios do sono;<br />
                        • Ansiedade e depressão.
                        14º Lei: Lei dos Fenômenos de Aplicação Energética de Vetores. <br />
                    </p>
                </div>
            </div>
        </div>
    )
}